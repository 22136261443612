import React from 'react'
import "./AboutBanner.scss"
import AboutBannerImg from "../../../images/about-banner.jpg";

const AboutBanner = () => {
  return (
    <section className='about_banner' style={{backgroundImage: `url(${AboutBannerImg})`}}>
        <div className="container">

        </div>
    </section>
  )
}

export default AboutBanner
